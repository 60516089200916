<template>
  <div>
    <!-- <v-app-bar class="navBar" style="margin: 0px; z-index:9;">
      <div
      style="display: flex; flex-direction: row; margin: 20px; justify-content: space-between; width: 100vw;"
      align="left"
      >
      <div style="width: 50%"   >
        <div style="display: flex; flex-direction: column; width: 100%">

          <div>
            <img
            class="logoClass"
            src="https://s3iconimages.mymedicine.com.mm/mymedicinelogo.svg"
            />
          </div>

          <div class="titleClass">
            <div>
              <span style="font-weight: 400; font-size: .8em" align="left">{{ $t("Customer.Home.Welcome") }},</span>
            </div>
            <div v-if="username">
              <span style="font-weight: 800; font-size: .8em" align="left">{{
                username
              }}</span>
            </div>
            <div v-if="!username">
              <span style="font-weight: 800; font-size: .8em" align="left">{{
                Guest,
              }}<a href="/login">Login</a></span>
            </div>
          </div>

        </div>
      </div>

          <div style="width: 50%" align="right">
             <V-avatar size="45" v-if="!iscustomer" style="padding-right:10px">
                      <img src="https://s3iconimages.mymedicine.com.mm/male.svg" alt="Image" />
                    </V-avatar>
      <v-btn icon>
        <img
        class="navIcon"
        src="https://s3iconimages.mymedicine.com.mm/refresh.svg"
        @click="refresh"
        />
      </v-btn>
     <v-btn icon v-show="false">
        <img
        class="navIcon"
        src="https://s3iconimages.mymedicine.com.mm/notification.svg"
        />
      </v-btn>
    </div>
    </div>
  </v-app-bar> -->

  <v-navigation-drawer
  v-model="drawer"
  absolute
  permanent
  stateless
  style="position: absolute; top:0px; height:100%;padding-left:6px; padding-bottom: 40px;"
  class="sidebar"
  v-if="!iscustomer"
  >
  <!--   v-if="pageName != 'DoctorVideoCall'" -->

  <v-list nav dense style="margin-top: 10px;display: flex;flex-direction: column;">
    <v-list-item-group
    v-model="group"
    active-class="deep-purple--text text--accent-4"
    >


    <v-list-item style="margin-top: 5px" @click="redirectToHomePage">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">

          <div style="width: 10%;">
            <img
            style="padding-top:17px;"
            src="https://s3iconimages.mymedicine.com.mm/Home_doc.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
             <p style="padding-top: 14px;margin-left: 8%;">Home</p>
          </div>
          <div style="width: 20%;">
            <img
              style="padding-top:22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'AdminHome' || pageName == 'DoctorHome' "
            />
            <img
            style="padding-top:22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />

          </div>

        </div>
      </v-list-item-title>
    </v-list-item>
   

    <v-list-item  v-if="!isadmin" @click="redirectToAppointmentHistory">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top:17px;"
            src="https://s3iconimages.mymedicine.com.mm/appointment_history.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8%;">Appointment History</p>
          </div>
          <div style="width: 20%">
            <img
            style="padding-top:22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'AppointmentHistory' "
            />
            <img
            style="padding-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />

          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item v-if="!isadmin" @click="redirectToDoctorAllPatients">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/patients_list.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">View All Patients</p>
          </div>
          <div style="width: 20%">
            <img
            style="padding-top:22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'ViewAllPatientDoc' "
            />
            <img
            style="padding-top:22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />

          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item style="margin-top: 5px" @click="redirectToWalkinDashboard" v-if="isadmin">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/patients_list.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Walk-in Dashboard</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'AdminWalkIn'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item style="margin-top: 5px" @click="redirectToGenerateSlots" v-if="isadmin">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Generate Slots</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'GenerateSlotsPage'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item style="margin-top: 5px" @click="redirectToEditSlots" v-if="isadmin">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Edit Doctor Slots</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'EditSlotsPage'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item style="margin-top: 5px" @click="redirectToViewAllDoctors" v-if="isadmin">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/view_doctor.svg"
            />
          </div>
          <div style="width: 70%;text-aligin:left" align="left">
            <p style="padding-top: 14px;margin-left: 8px;"> Doctors</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'ViewAllDoctors'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item style="margin-top: 5px" @click="redirectToViewAllPatients" v-if="isadmin">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/patients_list.svg"
            />
          </div>
          <div style="width: 70%;text-align:left" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">View Patients</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'ViewAllPatient'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>


    <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToAddDoctors" v-show="false">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/add_doctor.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Add Doctor</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'createDoctor'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>
    <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToAddAdmin">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/add_admin.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Add Admin</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'createAdmin'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>
    <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToViewSpecialization">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 17px;margin-left: 8px;">Specializations</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'ViewSpecialization'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>
    <!-- commented for hide verify devices -->
    <!-- <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToViewSuggestions">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 17px;margin-left: 8px;">Suggestions</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'ViewAllSuggestions'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item> -->
    <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToViewAllCoupons">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Coupons</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'CouponFamily'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>
    <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToViewAllOffers">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Offers</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'Offers'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>
    <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToViewAllPromos">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Promotions</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'AllPromos'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>
    <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToViewAllCorparatePackage">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Corporate Program</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'CorparatePackageHome'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>
    <v-list-item style="margin-top: 5px" v-if="!isadmin" @click="redirectToCPIHomePage" v-show="false">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">

          <div style="width: 10%;">
            <img
            style="padding-top:17px;"
            src="https://s3iconimages.mymedicine.com.mm/Home_doc.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
             <p style="padding-top: 14px;margin-left: 8%;">Anonymous Appointments</p>
          </div>
          <div style="width: 20%;">
            <img
              style="padding-top:22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'DoctorCPIHome' "
            />
            <img
            style="padding-top:22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />

          </div>

        </div>
      </v-list-item-title>
    </v-list-item>
    <v-list-item style="margin-top: 5px" v-if="!isadmin" @click="redirectToCPIAppointmentHistory" v-show="false">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">

          <div style="width: 10%;">
            <img
            style="padding-top:17px;"
            src="https://s3iconimages.mymedicine.com.mm/appointment_history.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
             <p style="padding-top: 14px;margin-left: 8%;">External Appointment History</p>
          </div>
          <div style="width: 20%;">
            <img
              style="padding-top:22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'CPIAppointmentHistory'"
            />
            <img
            style="padding-top:22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />

          </div>

        </div>
      </v-list-item-title>
    </v-list-item>
    <v-list-item style="margin-top: 5px" v-if="!isadmin" @click="redirectToProfile">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/Home_doc.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">View Profile</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'DoctorProfile'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToSpecPromotion">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Featured Doctors</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'adminSpecPromotion'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToServiceDoctors">
      <v-list-item-title>
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Service Doctors</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'ServiceDoctorDashboard'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToCreateAdminAppointment">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Create Appointment</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'adminCreateAppointment'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToExternalAppointment">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">External Appointment</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'adminCreateAppointment'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToQuickHelpAppointment">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Quick Help</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'adminCreateAppointment'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item>


    <!-- <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToAdminCommonHomePage">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Common HomePage</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'CHP'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item> -->

    <!-- <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToAdminEpharmacyHomePage">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">Epharmacy HomePage</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'EHP'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item> -->

    <!-- <v-list-item style="margin-top: 5px" v-if="isadmin" @click="redirectToAdminUserManagement">
      <v-list-item-title >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div style="width: 10%">
            <img
            style="padding-top: 17px;"
            src="https://s3iconimages.mymedicine.com.mm/generateslot.svg"
            />
          </div>
          <div style="width: 70%;text-align: left;" align="left">
            <p style="padding-top: 14px;margin-left: 8px;">User Management</p>
          </div>
          <div style="width: 20%">
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_color.svg"
            v-if="pageName == 'UserManagement'"
            />
            <img
            style="margin-top: 22px;"
            src="https://s3iconimages.mymedicine.com.mm/arrow_default.svg"
            v-else
            />
          </div>
        </div>
      </v-list-item-title>
    </v-list-item> -->

  </v-list-item-group>
  <div style="
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1%;
  ">

  <v-btn
  @click="gotoLoginPage()"
  color="#1467BF"
  style="position:absolute; bottom:0; left:0px; padding: 0% 30%; color: white; text-transform: capitalize;margin: 0px;width: 100%; height: 45px; border-radius: 0px; background-color: #1467BF;"
  >
  <div style="display: flex; flex-direction: row;">
    <div>
     <img style="margin-right:5px;  padding-top:5%;"
     src="https://s3iconimages.mymedicine.com.mm/admin_logout.svg"
     />
   </div>
   <div>
     <span> <div style="margin-top: 5px;">
     Logout </div>
   </span>
 </div>
</div>

</v-btn>
</div>


</v-list>

</v-navigation-drawer>
</div>
</template>

<script>
import axios from "axios";
export default {
  name: "sideBar",
  props: ["username","pageName"],
  mounted() {
    if (this.$router.currentRoute.fullPath.split("/")[1] == "admin") {
    this.currentUser = this.$cookies.get("token");
  }
  else{
    this.currentUser = this.$cookies.get("doctorToken");
  }
    if (this.$router.currentRoute.fullPath.split("/")[1] == "admin") {
      this.isadmin = true;
    }
    else {
      this.isadmin = false;
    }
    if (this.$router.currentRoute.fullPath.split("/")[1] == "customer" || this.$router.currentRoute.fullPath.split("/")[2] == "videoCall" ||this.$router.currentRoute.fullPath.split("/")[2] == "videocall") {
      this.iscustomer = true;
    }
    else {
      this.iscustomer = false;
    }
    if(!this.$router.currentRoute.fullPath.split("/")[1]){
      this.iscustomer = true;
    }
  },
  data() {
    return {
      switch1: true,
      drawer: false,
      group: null,
      currentUser : "",
      isadmin: false,
      iscustomer : false,
      Guest : "Guest"
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods:{
    redirectToSpecPromotion() {
      this.$router.push({
        name: "ViewHighlightSpec"
      });
    },
    redirectToWalkinDashboard()
    {
      this.$router.push({
        name: "WalkIn"
      });
    },
    refresh(){
      this.$router.go();
    },
    redirectToProfile() {
      if(this.isadmin ==  false){
        this.$router.push({
          name: "DoctorProfile"
        });
      }
    },
    redirectToViewAllCorparatePackage(){
      this.$router.push({
      name: "AllCorporatePackages"
    });
    },
    redirectToHomePage(){
      if (this.$router.currentRoute.fullPath.split("/")[1] == "admin"){
        this.$router.push({
          name: "AdminHome",
        });
      }
      else if (this.$router.currentRoute.fullPath.split("/")[1] == "doctor"){
       this.$router.push({
         name: "DoctorHome",
       });
     }
   },
   redirectToCPIHomePage(){
     if (this.$router.currentRoute.fullPath.split("/")[1] == "doctor"){
       this.$router.push({
         name: "DoctorCPIHome",
       });
     }
   },
   redirectToDoctorAllPatients(){
     this.$router.push({
      name: "DoctorViewAllPatient"
    });
   },
   redirectToAppointmentHistory(){
    this.$router.push({
      name: "AppointmentHistory"
    });
   },
   redirectToCPIAppointmentHistory(){
    this.$router.push({
      name: "CPIAppointmentHistory"
    });
   },
   
   redirectToViewSpecialization() {
    this.$router.push({
      name: "ViewSpecialization"
    });
  },
  redirectToViewSuggestions() {
    this.$router.push({
      name: "ViewAllSuggestions"
    });
  },
  redirectToViewAllCoupons() {
    this.$router.push({
      name: 'AllCoupons'
    });
  },
  redirectToViewAllPromos() {
    this.$router.push({
      name: 'AllPromos'
    });
  },
  redirectToViewAllOffers() {
    this.$router.push({
      name: 'Offers'
    });
  },
  redirectToCreateAdminAppointment() {
    this.$router.push({
      name: "AdminCreateAppointment"
    });
  },
  redirectToExternalAppointment() {
    this.$router.push({
      name: 'ExternalAppointmentList'
    });
  },
  redirectToQuickHelpAppointment() {
    this.$router.push({
      name: 'QuickHelpAppointmentList'
    });
  },
  redirectToAdminEpharmacyHomePage() {
    this.$router.push({
      name: "EHP"
    });
  },
  redirectToAdminCommonHomePage() {
    this.$router.push({
      name: "CHP"
    });
  },
  redirectToAdminUserManagement() {
    this.$router.push({
      name: "UserManagement"
    });
  },
  redirectToServiceDoctors() {
    this.$router.push({
      name: "ServiceDoctorDashboard"
    });
  },
  redirectToGenerateSlots()
  {
   this.$router.push({
     name: "GenerateSlots",
   });
 },
 redirectToEditSlots()
 {
  this.$router.push({
    name: "SlotsManagement"
  });
 },
 redirectToViewAllDoctors()
 {
   this.$router.push({
     name: "ViewAllDoctors",
   });
 },
 redirectToViewAllPatients()
 {
   this.$router.push({
     name: "ViewAllPatients",
   });

 },
 redirectToAddDoctors()
 {
   this.$router.push({
     name: "CreateDoctor",
   });
 },
 redirectToAddAdmin()
 {
   this.$router.push({
     name: "CreateAdmin",
   });
 },
 gotoLoginPage() {
  var deleteData = {
    idToken : this.currentUser
  }
  axios.post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken",deleteData)
  .then((deleteResponse) => {
    if(this.$router.currentRoute.fullPath.split("/")[1] == "admin")
    {
      this.$cookies.remove("token");
        this.$router.push({
        name: "AdminLogin",
    });
    }
    else if(this.$router.currentRoute.fullPath.split("/")[1] == "doctor")
    {
      this.$cookies.remove("doctorToken");
      this.$router.push({
        name: "DoctorLogin",
      });
    }
  })
  .catch((error) => {
  });
  localStorage.clear();
}
},
};
</script>

<style>
.navIcon {
  width: 32px;
}
.navBar {
  background-color: #ffffff !important;
  box-shadow: 0px 2px 15px 0px #00000026 !important;
  z-index: 1;
}
.sideBarClass {
  color: #1467bf;
  padding: 0px;
  /* margin-top: 20px; */
}
.sidebar{
  width: 100% !important;
  position: relative !important;
  height: 92vh !important;
}
.logoClass {
  width: 100px;
  /* height:10%; */
}
@media (max-width: 576px) {
 .sidebar, .logoClass, .titleClass {
  display: none !important;
}
}

/* For tablets */
@media (min-width: 768px) {
 .titleClass {
  display: none !important;
}
}
@media (max-width: 576px) {
 .titleClass {
  display: block !important;
}
}

/* For tablets */
@media (max-width: 768px) {
 .titleClass {
  display: block !important;
}
}
</style>